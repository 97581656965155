<template>
  <div class="newelectricity">
    <div class="top">
      <div class="left">
        <div class="title">
          <!-- 电费余额（USDT) -->
          Electricity Fee Bala
        </div>
        <div class="money">
          <span>
            ≈
          </span>
          <span>
            {{ powerFee }}
          </span>
        </div>
      </div>
      <div class="right" @click="go('electricitytopup')">
        <img src="../assets/new_my/btc.png" alt="" class="icon" />
        <div class="cz">
          Recharge

          <img src="../assets/new_my/right_icon.png" class="i_right" />
        </div>
      </div>
    </div>
    <div class="title">
      Deduction Records
    </div>
    <div class="item" v-for="(item, index) in eleList" :key="index">
      <div class="left">
        <div class="l_top">
          {{ item.type == 1 ? "recharge" : "Deduct" }} Electricity Fee
        </div>
        <div class="bottom">
          {{ item.ctime }}
        </div>
      </div>
      <div class="right">
        {{ item.type == 1 ? "+" : "-" }}{{ item.amount }}USDT
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue"

export default {
  name: "my",
  data() {
    return {
      value: "123",
      errorInfo: "",
      showKeyboard: true,
      powerFee: "",
      eleList: [],
    }
  },

  mounted() {
    this.getAssets()
    this.getList()
  },
  methods: {
    go(url) {
      this.$router.push(url)
    },

    // 获取资产信息
    getAssets() {
      this.$request
        .get("/user/assets")
        .then(
          (res) => {
            if (res.code == 200) {
              this.powerFee = res.data.powerFee
            } else {
              this.$toast(res.msg)
              if (res.code == 1018) {
                this.$router.push("/login")
              }
            }
            // 执行成功的回调函数
          },
          (error) => {
            console.log(error)
            // 执行失败的回调函数
          }
        )
        .catch((err) => {
          this.$toast(err)
        })
    },
    //提现
    getList() {
      this.$request
        .get("/user/powerfeeList")
        .then(
          (res) => {
            if (res.code == 200) {
              this.eleList = res.data
            } else {
              this.$toast(res.msg)
              if (res.code == 1018) {
                this.$router.push("/login")
              }
            }
            // 执行成功的回调函数
          },
          (error) => {
            console.log(error)
            // 执行失败的回调函数
          }
        )
        .catch(() => {})
    },
  },
}
</script>
<style scoped lang="scss">
.newelectricity {
  font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
  background: #f9f9f9;
  padding: 30px 20px;
  text-align: left;
  min-height: 100vh;
  .top {
    width: 100%;
    height: 141px;
    background: #f8de4d;
    border-radius: 14px;
    display: flex;
    justify-content: space-between;
    padding: 18px 0 0 20px;
    .left {
      display: flex;
      flex-direction: column;
      .title {
        font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
        font-weight: 700;
        font-size: 14px;
        color: #4a4a4a;
        margin-top: 12px;
      }
      .money {
        font-family: DINPro, DINPro;
        font-weight: bold;
        font-size: 30px;
        color: #333333;
        margin-top: 19px;
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      .icon {
        width: 60px;
        margin-right: 20px;
        margin-bottom: 10px;
      }
      .cz {
        width: 92px;
        height: 35px;
        background: #ffffff;
        border-radius: 8px 0px 0px 8px;
        font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
        font-weight: 700;
        font-size: 14px;
        color: #333333;
        line-height: 35px;
        text-align: center;

        .i_right {
          // margin-right: 2px;
          width: 10px;
          height: 10px;
        }
      }
    }
  }
  .title {
    font-weight: 700;
    font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
    font-size: 18px;
    color: #333333;
    margin: 30px 0 0;
  }
  .item {
    width: 100%;
    height: 80px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px 16px 16px;
    margin-top: 10px;
    font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
    font-weight: 700;
    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .l_top {
        font-size: 18px;
        color: #4a4a4a;
        text-align: left;
      }
      .bottom {
        font-size: 14px;
        color: #999999;
      }
    }
    .right {
      font-size: 16px;
      color: #333333;
    }
  }
}
</style>

<style lang="scss"></style>
